$font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

div.PrintForm {
  background-color: #ffffff;
  width: 1050px;
  margin: auto;
  display: block;
  background-size: contain;
  font-family: $font;
  font-size: 18px;
  hr {
    margin: 13px 0 !important;
  }
  div.simple {
    line-height: 150%;
  }
  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 200%;
    color: #0080ff;
  }
  input[type="text"],
  textarea {
    width: 100%;
    font-family: $font;
    font-weight: bold;
  }
  section.page {
    background-color: rgba(255, 255, 255, 0.8);
    display: block;
    box-sizing: border-box;
    padding: 10px;
    page-break-after: always;
  }
  section.declaration {
    p {
      font-size: 14px;
      line-height: 140%;
    }
  }
  section.PageOne {
    div.header {
      display: grid;
      grid-template-columns: 1fr 160px 160px;
      grid-column-gap: 60px;
      h1 {
        font-size: 30px;
        font-weight: 700;
      }
    }
  }
}
