@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css");
@import "../../node_modules/react-datepicker/dist/react-datepicker.css";

$primary-color: #e30613;
body {
  background-color: #ededed;
}

header {
  margin: auto;
  display: grid;
  grid-template-columns: 130px 1fr 120px;
  background-color: #ffffff;
  padding: 20px 30px !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  img {
    width: 100%;
    display: block;
  }
  h1 {
    font-size: 25px !important;
    padding: 5px;
    text-align: center;
    line-height: 150% !important;
    span {
      color: $primary-color;
    }
  }
}

.IndexComponent {
  background-color: #ffffff;
  padding: 30px !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-top: 30px;
}

.react-datepicker-wrapper {
  display: block !important;
}

.hiding {
  display: none !important;
}

.showing {
  display: block !important;
}
